<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'

export default {
  name: 'training',
  data() {
    return {
      ...api.command.getState(),
      storeData: [],
      dataId: null,
      areaArr: [],
    }
  },
  mounted() {
    this.handleGetInit()
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaArr',
    })
  },
  methods: {
    handleGetInit() {
      api.command.getList.call(this, {
        url: '/farmYsTrain/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'trainName',
        },
        {
          label: '',
          text: '',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'trainName',
          title: '培训名称',
          filter: true,
          align: 'left',
          sorter: (a, b) => a.trainName.length - b.trainName.length,
        },
        {
          dataIndex: 'trainTime',
          title: '培训时间',
          align: 'left',
        },
        {
          dataIndex: 'fullAddress',
          title: '培训地址',
          align: 'left',
        },
        {
          dataIndex: 'trainStatus',
          title: '培训情况',
          type: 'badge',
          //   filters: [
          //     {
          //       text: '未开始',
          //       value: '0'
          //     },
          //     {
          //       text: '已培训',
          //       value: '1'
          //     }
          //   ],
          onExport: (records) => {
            return ['未开始', '已培训'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '未开始' : '已培训',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          //   filters: [
          //     {
          //       text: '上架',
          //       value: '0'
          //     },
          //     {
          //       text: '下架',
          //       value: '1'
          //     }
          //   ],
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: records.trainStatus == 0,
                name: '开始培训',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: '/farmYsTrain/startTrain?id=' + records.id,
                  })
                },
              },
              {
                display: true,
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmYsTrain/${records.upDown == 0 ? 'down' : 'up'}`,
                    params: {
                      ids: [records.id],
                    },
                  })
                },
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
              {
                display: true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delDelArr.call(this, {
                    url: '/farmYsTrain/delete',
                    params: {
                      ids: [records.id],
                    },
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.delDelArr.call(this, {
                  url: '/farmYsTrain/delete',
                  params: { ids },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmYsTrain/up',
                  params: { ids },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmYsTrain/down',
                  params: { ids },
                })
              },
            },
          ],
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          title: '基本信息',
          form: [
            {
              name: '培训名称',
              type: 'input',
              key: 'trainName',
              labelAlign: 'left',
            },
            {
              name: '培训时间',
              type: 'datePicker',
              key: 'trainTime',
              labelAlign: 'left',
              props: {
                showTime: true,
                format: 'YYYY-MM-DD HH:mm:ss',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择培训日期时间',
              },
            },
            {
              type: 'row',
              name: '培训地址',
              cols: 24,
              gutter: 5,
              children: [
                {
                  type: 'cascader',
                  key: 'areaCode',
                  label: '培训地址',
                  placeholder: '请选择地址',
                  typeData: this.areaArr,
                  cols: 10,
                },
                {
                  type: 'input',
                  key: 'address',
                  label: '地址',
                  cols: 14,
                },
              ],
            },
          ],
        },
        {
          title: '培训描述',
          form: [
            {
              name: '描述',
              type: 'textArea',
              key: 'trainContent',
              labelAlign: 'left',
            },
          ],
        },
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/api/farmYsTrain/detail/' + records.id).then((result) => {
          if (result.code == 200) {
            let sj = result.data
            let a = [sj.provinceCode, sj.cityCode, sj.areaCode]
            let objectData = {
              ...sj,
              areaCode: a,
            }
            this.handleStatePopup(objectData)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    getSumbitData(dataObj) {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = dataObj[i]
      })
      return obj
    },
    handleStatePopup(source = {}) {
      let that = this
      apiTool.showDrawer({
        title: '培训信息',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          const codeData = this.getSumbitData(data.areaCode)
          api.command[!that.dataId ? 'create' : 'editPost']
            .call(that, {
              url: `/farmYsTrain/${!that.dataId ? 'add' : 'update'}`,
              params: {
                ...data,
                ...codeData,
                id: that.dataId,
                fullAddress: `${codeData.provinceName + codeData.cityName + codeData.areaName + ' ' + data.address}`,
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
